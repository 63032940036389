<template>
    <div>
      <div>
        <b-button
          @click="resetForm"
          type="button"
          pill
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop2"
          >Ajouter</b-button
        >
      </div>
      <br />
      <div>
        <div class="container">
        <div class="row">
        <div  v-if="showError" class="alert alert-danger col-md-3 offset-md-3" role="alert">
            <p v-for="item in message" :key="item">{{ item}}</p>
       </div>
      </div>
      <div class="row">
      <div v-if="showSuccess" class="alert alert-success col-md-3 offset-md-3" role="alert">
          <p>{{ message1}}</p>
      </div>
      </div>
      </div>
        <b-row>
          <b-col lg="6" class="my-1">
            <b-form-group
              label-for="filter-input"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  class="rounded-pill"
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Recherche"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          fixed
          responsive
          striped
          hover
          bordered
          :items="companies"
          :fields="fields"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(countries)="data">
            <li v-for="pays in data.value" :key="pays" >{{ pays.name }}</li>
          </template>
          <template #cell(Actions)="row">
           <ul
              class="d-flex justify-content-betweens"
            >
              <li  @click="show(row.item)"  data-bs-toggle="modal" data-bs-target="#staticBackdrop3">
                <a href="#">
                  <img
                    src="../../assets/images/icon/show-regular-24.png"
                    alt="call-2"
                  />
                </a>
              </li>
  
              <li  @click="edit(row.item)"  data-bs-toggle="modal" data-bs-target="#staticBackdrop4">
                <a href="#">
                  <img src="../../assets/images/icon/bx-highlight.svg"/>
                </a>
              </li>
              <li  @click="deleted(row.item)"  data-bs-toggle="modal" data-bs-target="#staticBackdrop5">
                <a href="#">
                  <img src="../../assets/images/icon/trash-2.svg"  />
                </a>
              </li>
            </ul> 
          </template>
        </b-table>
      </div>
    </div>
    <div
      class="modal fade"
      id="staticBackdrop2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Ajouter une compagnie
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            
            <p class="my-0">Nom</p>
            <b-form-input class="mb-2" v-model="name"></b-form-input>
  
            <p class="my-0">Pays</p>
            <b-form-select
              multiple
              v-model="countries"
              :options="countries_list"
              autocomplete="on"
              class="mb-3"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </div>
          <div class="modal-footer">
            <b-button variant="danger" data-bs-dismiss="modal" @click="resetForm"
              >Quitter</b-button
            >
            <b-button
              :disabled="!isFormValid"
              variant="success"
              data-bs-dismiss="modal"
              @click="save"
              >Valider</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="staticBackdrop3"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Détails de la compagnie
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p class="my-0">Nom</p>
            <b-form-input disabled class="mb-2" v-model="name"></b-form-input>
  
            <p class="my-0">Pays</p>
            <b-form-select
              disabled
              multiple
              v-model="countries"
              :options="countries_list"
              autocomplete="on"
              class="mb-3"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </div>
         
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="staticBackdrop4"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Modifier la compagnie
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            
            <p class="my-0">Nom</p>
            <b-form-input class="mb-2" v-model="name"></b-form-input>
  
            <p class="my-0">Pays</p>
            <b-form-select
              multiple
              v-model="countries"
              :options="countries_list"
              autocomplete="on"
              class="mb-3"
              value-field="id"
              text-field="name"
            ></b-form-select>
  
          </div>
          <div class="modal-footer">
            <b-button variant="danger" data-bs-dismiss="modal" @click="resetForm"
              >Quitter</b-button
            >
            <b-button
              :disabled="!isFormValid"
              variant="success"
              data-bs-dismiss="modal"
              @click="updateValidate"
              >Modifier</b-button
            >
            
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="staticBackdrop5"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Supprimer la compagnie
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>Voulez-vous supprimer la compagnie? <b>{{ compagnyName }}</b></p>
          </div>
          <div class="modal-footer">
            <b-button variant="danger" data-bs-dismiss="modal" @click="resetForm"
              >Quitter</b-button
            >
  
            <b-button
              variant="success"
              data-bs-dismiss="modal"
              @click="deletedValidate"
              >supprimer</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "../../axios"
  export default {
    name: "DataTable",
    data() {
      return {
        showSuccess:false,
        showError:false,
        message:[],
        message1:"",
        filter: '',
        name: "",
        countries_list:[],
        countries: [],
        companies: [],
        usersRech: [],
       
        fields: [
          /*   {
              key: 'index',
              label: 'index'
            }, */
  
         
          {
            key: "name",
            label: "Nom",
          },
          {
            key: "countries",
            label: "Pays",
          },
          "Actions",
        ],
        companyId : '',
        compagnyName: ''
      };
    },
    computed: {
      isFormValid() {
        return (
          this.name !== "" &&
          this.countries.length !== 0
        );
      },
     
    },
    watch: {
        filter(value){
          if(value){
            let namefc= value.toUpperCase()
            let rep=[]
          for (var i = 0; i < this.usersRech.length; i++) {
                  if(this.usersRech [i].rechInfo.toUpperCase().includes(namefc)){
                  rep.push(this.usersRech [i])
                  }
          }
          if(rep.length){
            this.companies.length = 0
            this.companies = rep.sort((a,b)=>a.rechInfo.localeCompare(b.rechInfo))
          }
  
          if(rep.length===0){
            this.companies = []
          }
       }else{
            this.companies = []
            window.CompagniesTransvie.getCompanies()
          }
        }
    },
    mounted() {
      this.totalRows = this.companies.length
    },
    methods: {
      closeTime(){
      setTimeout(
        function () {
          this.showSuccess = false
          this.showError = false
        }.bind(this), 10000)
    },
      deletedValidate(){
        axios.delete(`companies/${ this.companyId}/`)
              .then(function () {
                window.CompagniesTransvie.message.push('Compagnie supprimée avec succès')
                window.CompagniesTransvie.showError = true
               window.CompagniesTransvie.getCompanies()
               window.CompagniesTransvie.closeTime()

            })
              .catch(() => {
  
            })
         },
      deleted (row) {
        this.companyId = row.id
        this.compagnyName = row.name
      },
      save() {
          this.input = {
            name: this.name,
            countries: this.countries
        }
        axios.post("companies/", this.input)
          .then(function () {
            window.CompagniesTransvie.message1='Compagnie ajoutée avec succès'
            window.CompagniesTransvie.showSuccess = true
            window.CompagniesTransvie.getCompanies()
            window.CompagniesTransvie.closeTime()

          })
          .catch(function (error) {
            window.CompagniesTransvie.showError = true
            window.CompagniesTransvie.message=[]
            if (error.response.data.name) {
              window.CompagniesTransvie.message.push(error.response.data.name[0])
            } if (error.response.data.countries) {
              window.CompagniesTransvie.message.push(error.response.data.countries[0])
            }
            window.CompagniesTransvie.closeTime()

          })
      },
      updateValidate() {
          this.input = {
            name: this.name,
            countries: this.countries
            }
        axios.patch(`companies/${this.companyId}/`,this.input)
          .then(function () {
            window.CompagniesTransvie.message1='Compagnie modifiée avec succès'
            window.CompagniesTransvie.showSuccess = true
            window.CompagniesTransvie.getCompanies()
            window.CompagniesTransvie.closeTime()
          })
          .catch(function (error) {
            window.CompagniesTransvie.showError = true
            window.CompagniesTransvie.message=[]
            if (error.response.data.name) {
              window.CompagniesTransvie.message.push(error.response.data.name[0])
            } if (error.response.data.countries) {
              window.CompagniesTransvie.message.push(error.response.data.countries[0])
            }
            window.CompagniesTransvie.closeTime()

          })
      },
      edit(tr){
          this.resetForm()
          this.companyId = tr.id
          this.name = tr.name
        for (var i = 0; i < tr.countries.length; i++) {
            this.countries.push(tr.countries[i].id)
        }
      },
      show(tr){
          this.resetForm()
          this.name = tr.name
          for (var i = 0; i < tr.countries.length; i++) {
            this.countries.push(tr.countries[i].id)
          }   
     },
      resetForm() {
        this.name = ""
        this.countries = []
      },
      getCountries() {
        axios
          .get("countries/")
          .then((response) => {
            this.countries_list = response.data
          })
          .catch(() => {})
      },
     
      getCompanies() {
        axios
          .get("companies/")
          .then((response) => {
            this.companies = []
            this.usersRech = []
            let resultat =''
            const results = response.data
            results.forEach(event => {
                event.countries.forEach(function(element) {
                    resultat += ' '+element.name
                })
                this.companies.push({
                  id: event.id,
                  name:event.name,
                  countries:event.countries,
                  rechInfo:event.name + resultat
                })
                this.usersRech.push({
                  id: event.id,
                  name:event.name,
                  countries:event.countries,
                  rechInfo:event.name + resultat
                })
            })
          })
          .catch(() => {});
      }
    },
  
    created() {
      window.CompagniesTransvie = this
      this.getCompanies()
      this.getCountries()
    },
  };
  </script>
  
  <style lang="scss">
  li {
    list-style-type: none;
  }
  
  /* Max width 767px */
  @media only screen and (max-width: 767px) {
    .contact-list-area {
      .table-responsive {
        overflow: auto;
        table {
          tbody {
            tr {
              td {
                white-space: nowrap;
                padding: 15px !important;
              }
            }
          }
        }
      }
      .table tbody {
        tr td ul li {
          width: 20px;
        }
      }
    }
  }
  
  /* Min width 768px to Max width 991px */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-list-area {
      .table-responsive {
        overflow: auto;
        table {
          tbody {
            tr {
              td {
                white-space: nowrap;
                padding: 15px !important;
                .form-check img {
                  width: 30px;
                }
              }
            }
          }
        }
      }
      .table tbody {
        tr td ul li {
          width: 20px;
        }
      }
    }
  }
  </style>
  