<template>
  <div>
    <div>
      <b-button
        @click="resetForm"
        type="button"
        pill
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop2"
        >Ajouter</b-button
      >
    </div>
    <br />
    <div>
      <div class="container">
        <div class="row">
        <div  v-if="showError" class="alert alert-danger col-md-3 offset-md-3" role="alert">
            <p v-for="item in message" :key="item">{{ item}}</p>
       </div>
      </div>
      <div class="row">
      <div v-if="showSuccess" class="alert alert-success col-md-3 offset-md-3" role="alert">
          <p>{{ message1}}</p>
      </div>
      </div>
      </div>
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label-for="filter-input"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                class="rounded-pill"
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Recherche"
              ></b-form-input>

              
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        fixed
        responsive
        striped
        hover
        bordered
        :items="users"
        :fields="fields"
      >
        <template #cell(role)="data">
          {{ roleObject[data.item.role] }}
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(company)="data">
          <i v-if="data.value">{{ data.value.name }}</i>
        </template>
        <template #cell(Actions)="row">
         <ul
            class="d-flex justify-content-betweens"
          >
            <li  @click="show(row.item)"  data-bs-toggle="modal" data-bs-target="#staticBackdrop3">
              <a href="#">
                <img
                  src="../../assets/images/icon/show-regular-24.png"
                  alt="call-2"
                />
              </a>
            </li>

            <li  @click="edit(row.item)"  data-bs-toggle="modal" data-bs-target="#staticBackdrop4">
              <a href="#">
                <img src="../../assets/images/icon/bx-highlight.svg"/>
              </a>
            </li>
            <li  @click="deleted(row.item)"  data-bs-toggle="modal" data-bs-target="#staticBackdrop5">
              <a href="#">
                <img src="../../assets/images/icon/trash-2.svg"  />
              </a>
            </li>
          </ul> 
        </template>
      </b-table>
    </div>
  </div>
  <div
    class="modal fade"
    id="staticBackdrop2"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Ajouter un utilisateur
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="my-0">Nom</p>
          <b-form-input class="mb-2" v-model="last_name"></b-form-input>

          <p class="my-0">Prénom(s)</p>
          <b-form-input class="mb-2" v-model="first_name"></b-form-input>

          <p class="my-0">Rôle</p>
          <b-form-select
            v-model="role"
            :options="options"
            autocomplete="on"
            class="mb-3"
            value-field="item"
            text-field="name"
          ></b-form-select>

          <p class="my-0">E-mail</p>
          <b-form-input
            type="email"
            class="mb-2"
            v-model="email"
          ></b-form-input>

          <p class="my-0">Téléphone</p>
          <b-form-input
            type="email"
            class="mb-2"
            v-model="phoneNumber"
          ></b-form-input>

          <p v-if="role == 'user'" class="my-0">Compagnie</p>
          <b-form-select
            v-if="role == 'user'"
            v-model="company"
            :options="companies"
            autocomplete="on"
            class="mb-3"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </div>
        <div class="modal-footer">
          <b-button variant="danger" data-bs-dismiss="modal" @click="resetForm"
            >Quitter</b-button
          >
          <b-button
            v-if="role == 'admin'"
            :disabled="!isFormValid"
            variant="success"
            data-bs-dismiss="modal"
            @click="save"
            >Valider</b-button
          >
          <b-button
            v-if="role == 'user'"
            :disabled="!isFormValidUser"
            variant="success"
            data-bs-dismiss="modal"
            @click="save"
            >Valider</b-button
          >
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="staticBackdrop3"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Détails de l'utilisateur
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="my-0">Nom</p>
          <b-form-input disabled class="mb-2" v-model="last_name"></b-form-input>

          <p class="my-0">Prénom(s)</p>
          <b-form-input disabled class="mb-2" v-model="first_name"></b-form-input>

          <p class="my-0">Rôle</p>
          <b-form-select
            disabled
            v-model="role"
            :options="options"
            autocomplete="on"
            class="mb-3"
            value-field="item"
            text-field="name"
          ></b-form-select>

          <p class="my-0">E-mail</p>
          <b-form-input
            disabled
            type="email"
            class="mb-2"
            v-model="email"
          ></b-form-input>

          <p class="my-0">Téléphone</p>
          <b-form-input
            disabled
            type="email"
            class="mb-2"
            v-model="phoneNumber"
          ></b-form-input>

          <p v-if="role == 'user'" class="my-0">Compagnie</p>
          <b-form-select
            disabled
            v-if="role == 'user'"
            v-model="company"
            :options="companies"
            autocomplete="on"
            class="mb-3"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </div>
       
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="staticBackdrop4"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Modifier l'utilisateur
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="my-0">Nom</p>
          <b-form-input  class="mb-2" v-model="last_name"></b-form-input>

          <p class="my-0">Prénom(s)</p>
          <b-form-input  class="mb-2" v-model="first_name"></b-form-input>

          <p class="my-0">Rôle</p>
          <b-form-select
            
            v-model="role"
            :options="options"
            autocomplete="on"
            class="mb-3"
            value-field="item"
            text-field="name"
          ></b-form-select>

          <p class="my-0">E-mail</p>
          <b-form-input
            
            type="email"
            class="mb-2"
            v-model="email"
          ></b-form-input>

          <p class="my-0">Téléphone</p>
          <b-form-input
            type="tel"
            class="mb-2"
            v-model="phoneNumber"
          ></b-form-input>

          <p v-if="role == 'user'" class="my-0">Compagnie</p>
          <b-form-select
            v-if="role == 'user'"
            v-model="company"
            :options="companies"
            autocomplete="on"
            class="mb-3"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </div>
        <div class="modal-footer">
          <b-button variant="danger" data-bs-dismiss="modal" @click="resetForm"
            >Quitter</b-button
          >
          <b-button
            v-if="role == 'admin'"
            :disabled="!isFormValid"
            variant="success"
            data-bs-dismiss="modal"
            @click="updateValidate"
            >Modifier</b-button
          >
          <b-button
            v-if="role == 'user'"
            :disabled="!isFormValidUser"
            variant="success"
            data-bs-dismiss="modal"
            @click="updateValidate"
            >Modifier</b-button
          >
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="staticBackdrop5"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Supprimer l'utilisateur
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>Voulez-vous supprimer l'utilisateur? <b>{{ userName }}</b></p>
        </div>
        <div class="modal-footer">
          <b-button variant="danger" data-bs-dismiss="modal" @click="resetForm"
            >Quitter</b-button
          >

          <b-button
            variant="success"
            data-bs-dismiss="modal"
            @click="deletedValidate"
            >supprimer</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../axios"
export default {
  name: "DataTable",
  data() {
    return {
      showSuccess:false,
      showError:false,
      message:[],
      message1:"",
      filter: '',
      userRole: "",
      first_name: "",
      last_name: "",
      role: "",
      email: "",
      phoneNumber: "",
      company: "",
      companies: [],
      users: [],
      usersRech: [],
      options: [
        { item: "admin", name: "Adminstrateur" },
        { item: "user", name: "Utilisateur" },
      ],
      fields: [
        /*   {
            key: 'index',
            label: 'index'
          }, */

        {
          key: "last_name",
          label: "Nom",
        },
        {
          key: "first_name",
          label: "Prénom(s)",
        },
        {
          key: "role",
          label: "Rôle",
        },
        {
          key: "email",
          label: "E-mail",
        },
        {
          key: "phoneNumber",
          label: "Téléphone",
        },
        {
          key: "company",
          label: "Compagnies",
        },
        "Actions",
      ],
      roleObject: {
        admin: "Administrateur",
        user: "Utilisateur",
      },
      userId : '',
      userName: ''
    };
  },
  computed: {
    isFormValid() {
      return (
        this.first_name !== "" &&
        this.last_name !== "" &&
        this.role !== "" &&
        this.email !== "" &&
        this.phoneNumber !== ""
      );
    },
    isFormValidUser() {
      return (
        this.first_name !== "" &&
        this.last_name !== "" &&
        this.role !== "" &&
        this.email !== "" &&
        this.phoneNumber !== "" &&
        this.company !== ""
      );
    },
  },
  watch: {
      filter(value){
        if(value){
          let namefc= value.toUpperCase()
          let rep=[]
        for (var i = 0; i < this.usersRech .length; i++) {
                if(this.usersRech [i].rechInfo.toUpperCase().includes(namefc)){
                rep.push(this.usersRech [i])
                }
        }
        if(rep.length){
          this.users.length = 0
          this.users = rep.sort((a,b)=>a.rechInfo.localeCompare(b.rechInfo))
        }

        if(rep.length===0){
          this.users = []
        }
     }else{
          this.users = []
          window.UsersTranvie.getUsers()
        }
      }
  },
  mounted() {
    this.totalRows = this.users.length
  },
  methods: {
    closeTime(){
      setTimeout(
        function () {
          this.showSuccess = false
          this.showError = false
        }.bind(this), 10000)
    },
    deletedValidate(){
      axios.delete(`users/${ this.userId}/`)
            .then(function () {
             window.UsersTranvie.message.push('Utilisateur supprimé avec succès')
             window.UsersTranvie.showError = true
             window.UsersTranvie.getUsers()
             window.UsersTranvie.closeTime()
          })
            .catch(() => {

          })
       },
    deleted (row) {
      this.userId = row.id
      this.userName = row.last_name+' '+row.first_name
    },
    save() {
      if (this.role === "user") {
        this.input = {
          first_name: this.first_name,
          last_name: this.last_name,
          role: this.role,
          email: this.email,
          phoneNumber: this.phoneNumber,
          company: this.company
        }
      } else {
        this.input = {
          first_name: this.first_name,
          last_name: this.last_name,
          role: this.role,
          email: this.email,
          phoneNumber: this.phoneNumber
        }
      }
      axios.post("users/", this.input)
        .then(function () {
          window.UsersTranvie.message1='Utilisateur ajouté avec succès'
          window.UsersTranvie.showSuccess = true
          window.UsersTranvie.getUsers()
          window.UsersTranvie.closeTime()
        })
        .catch(function (error) {
          window.UsersTranvie.showError = true
          window.UsersTranvie.message=[]
            if (error.response.data.last_name) {
              window.UsersTranvie.message.push(error.response.data.last_name[0])
            } if (error.response.data.first_name) {
              window.UsersTranvie.message.push(error.response.data.first_name[0])
            } if (error.response.data.company) {
              window.UsersTranvie.message.push(error.response.data.company[0])
            } if (error.response.data.phoneNumber) {
              window.UsersTranvie.message.push(error.response.data.phoneNumber[0])
            } if (error.response.data.email) {
              window.UsersTranvie.message.push(error.response.data.email[0])
            } if (error.response.data.role) {
              window.UsersTranvie.message.push(error.response.data.role[0])
            }
            window.UsersTranvie.closeTime()

        })
    },
    updateValidate() {
      if (this.role === "user") {
        this.input = {
          first_name: this.first_name,
          last_name: this.last_name,
          role: this.role,
          email: this.email,
          phoneNumber: this.phoneNumber,
          company: this.company
        }
      } else {
        this.input = {
          first_name: this.first_name,
          last_name: this.last_name,
          role: this.role,
          email: this.email,
          phoneNumber: this.phoneNumber
        }
      }
      axios.patch(`users/${this.userId}/`,this.input)
        .then(function () {
          window.UsersTranvie.message1='Utilisateur modifié avec succès'
          window.UsersTranvie.showSuccess = true
          window.UsersTranvie.getUsers()
          window.UsersTranvie.closeTime()
        })
        .catch(function (error) {
          window.UsersTranvie.showError = true
          window.UsersTranvie.message=[]
            if (error.response.data.last_name) {
              window.UsersTranvie.message.push(error.response.data.last_name[0])
            } if (error.response.data.first_name) {
              window.UsersTranvie.message.push(error.response.data.first_name[0])
            } if (error.response.data.company) {
              window.UsersTranvie.message.push(error.response.data.company[0])
            } if (error.response.data.phoneNumber) {
              window.UsersTranvie.message.push(error.response.data.phoneNumber[0])
            } if (error.response.data.email) {
              window.UsersTranvie.message.push(error.response.data.email[0])
            } if (error.response.data.role) {
              window.UsersTranvie.message.push(error.response.data.role[0])
            }
            window.UsersTranvie.closeTime()
        })
    },
    edit(tr){
        this.userId = tr.id
        this.first_name = tr.first_name
        this.last_name = tr.last_name
        this.role = tr.role
        this.email = tr.email
        this.phoneNumber = tr.phoneNumber 
        this.company = tr.company.id
    },
    show(tr){
        this.first_name = tr.first_name
        this.last_name = tr.last_name
        this.role = tr.role
        this.email = tr.email
        this.phoneNumber = tr.phoneNumber 
        this.company = tr.company.id
    },
    resetForm() {
      this.first_name = ""
      this.last_name = ""
      this.role = ""
      this.email = ""
      this.phoneNumber = ""
      this.company = ""
    },
    getCompanies() {
      axios
        .get("companies/")
        .then((response) => {
          this.companies = response.data
        })
        .catch(() => {})
    },
   
    getUsers() {
      axios
        .get("users/")
        .then((response) => {
          this.users = []
          this.usersRech = []
          const results = response.data
            results.forEach(event => {
              if(event.company){
                this.users.push({
                  id: event.id,
                  last_name:event.last_name,
                  first_name:event.first_name,
                  phoneNumber:event.phoneNumber,
                  email:event.email,
                  company:event.company,
                  role:event.role,
                  created_at:event.created_at,
                  updated_at:event.updated_at,
                  rechInfo:event.last_name +' '+event.first_name+' '+event.phoneNumber+' '+event.email+' '+event.company.name+' '+event.role
                })
                this.usersRech.push({
                  id: event.id,
                  last_name:event.last_name,
                  first_name:event.first_name,
                  phoneNumber:event.phoneNumber,
                  email:event.email,
                  company:event.company,
                  role:event.role,
                  created_at:event.created_at,
                  updated_at:event.updated_at,
                  rechInfo:event.last_name +' '+event.first_name+' '+event.phoneNumber+' '+event.email+' '+event.company.name+' '+event.role
                })
              }else{
                this.users.push({
                  id: event.id,
                  last_name:event.last_name,
                  first_name:event.first_name,
                  phoneNumber:event.phoneNumber,
                  email:event.email,
                  company:event.company,
                  role:event.role,
                  created_at:event.created_at,
                  updated_at:event.updated_at,
                  rechInfo:event.last_name +' '+event.first_name+' '+event.phoneNumber+' '+event.email+' '+event.role
                })
                this.usersRech.push({
                  id: event.id,
                  last_name:event.last_name,
                  first_name:event.first_name,
                  phoneNumber:event.phoneNumber,
                  email:event.email,
                  company:event.company,
                  role:event.role,
                  created_at:event.created_at,
                  updated_at:event.updated_at,
                  rechInfo:event.last_name +' '+event.first_name+' '+event.phoneNumber+' '+event.email+' '+event.role
                })
              }
            })
        })
        .catch(() => {});
    },
  },

  created() {
    window.UsersTranvie = this
    this.userRole = JSON.parse(localStorage.getItem("userData")).role
    this.getUsers()
    this.getCompanies()
  },
};
</script>

<style lang="scss">
li {
  list-style-type: none;
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  .contact-list-area {
    .table-responsive {
      overflow: auto;
      table {
        tbody {
          tr {
            td {
              white-space: nowrap;
              padding: 15px !important;
            }
          }
        }
      }
    }
    .table tbody {
      tr td ul li {
        width: 20px;
      }
    }
  }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-list-area {
    .table-responsive {
      overflow: auto;
      table {
        tbody {
          tr {
            td {
              white-space: nowrap;
              padding: 15px !important;
              .form-check img {
                width: 30px;
              }
            }
          }
        }
      }
    }
    .table tbody {
      tr td ul li {
        width: 20px;
      }
    }
  }
}
</style>
