<template>
    <div class="error-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="error-content card-box-style">
                        <h1>404</h1>
                        <h3>Oops! Page Not Found</h3>
                        <p>The page you were looking for could not be found.</p>
                        <router-link class="btn btn btn-primary" to="/">Return To Home Page</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErrorPage'
}
</script>

<style lang="scss">
.error-area {
	height: 100vh;
}
.error-content {
	text-align: center;
	max-width: 500px;
	margin: auto;
	h1 {
		font-size: 150px;
		line-height: 1;
	}
	h3 {
		font-size: 20px;
		margin-bottom: 10px;
	}
	p {
		margin-bottom: 30px;
	}
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .error-content {
        h1 {
            font-size: 100px;
            margin-top: 0;
        }
    }
}
</style>