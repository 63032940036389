<template>
  <div class="account-area">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="account-content">
            <div class="account-header">
              <!--                                 <img src="../../assets/images/main-logo.svg" alt="main-logo">-->
              <h1>
                <strong style="color: #0263ac">Trans</strong
                ><strong style="color: #00993c">Vie</strong><span></span>
              </h1>

              <h3>Connexion</h3>
            </div>
            <div v-if="showError" class="alert alert-danger" role="alert">
            {{ message}}
            </div>
            <form @submit.prevent class="account-wrap">
              <div class="form-group mb-24 icon">
                <input
                  type="email"
                  v-model="email"
                  class="form-control"
                  placeholder="Email"
                />
                <img src="../../assets/images/icon/sms.svg" alt="sms" />
              </div>
              <div class="form-group mb-24 icon">
                <input
                  type="password"
                  v-model="password"
                  class="form-control"
                  placeholder="Mot de passe"
                />
                <img src="../../assets/images/icon/key.svg" alt="key" />
              </div>
              <div class="form-group mb-24">
                <router-link to="/forget-password" class="forgot"
                  >Mot de passe oublié ?</router-link
                >
              </div>
              <div class="form-group mb-24">
                <b-button
                  :disabled="!isFormValid"
                  @click="login"
                  variant="primary"
                  class="default-btn"
                  >Se connecter</b-button
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../axios"
export default {
  name: "LoginPage",
  data: () => {
    return {
      showError:false,
      message:"",
      email: "",
      password: "",
    };
  },
  computed: {
    isFormValid() {
      return this.email !== "" && this.password !== ""
    },
  },

  methods: {
    login() {
      const Url = "/login/"
      const config = {
        method: "POST",
        url: Url,
        data: {
          username: this.email,
          password: this.password
        },
      }

      axios(config)
        .then((response) => {
          window.ChangePasswordTransvie.clearLocalstorage()
          let passwordChanged = response.data.userData.passwordChanged
          localStorage.setItem("accessToken", response.data.accessToken)
          localStorage.setItem("userData",JSON.stringify(response.data.userData))
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data.accessToken}`
          axios.defaults = JSON.parse(JSON.stringify(axios.defaults))
          if (!passwordChanged === true) {
            this.showError = false
            this.$router.push("/change-password")
          } else {
            this.showError = false
            this.$router.push("/")
          }
        })
        .catch((error) => {
          this.message = error.response.data.non_field_errors[0]
          if(this.message!==''){
            this.showError = true
          }
          this.closeTime()
          //this.showError(error.response.data.non_field_errors[0])
        });
    },

    clear() {
      this.email = ""
      this.password = ""
    },
    clearLocalstorage () {
		if (localStorage.getItem('accessToken')) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userData')
        localStorage.removeItem('ag_script_item')
      }
    },
    closeTime(){
      setTimeout(
        function () {
          this.showError = false
        }.bind(this), 5000)

    }
  },

  mounted() {
    document.body.classList.add("body-bg-f8faff");
  },
  created() {
    window.ChangePasswordTransvie = this;
   
  },
}
</script>

<style lang="scss">
.account-area {
  background-image: url(../../assets/images/account-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 100vh;
}
.account-content {
  max-width: 510px;
  margin: auto;
  background-color: var(--white-color);
  border-radius: 30px;
  padding: 50px;
}
.account-header {
  text-align: center;
  img {
    margin-bottom: 30px;
  }
  h3 {
    margin-bottom: 30px;
    padding-top: 30px;
    border-top: 1px solid #ebf1ff;
    font-size: 20px;
  }
}
.account-wrap {
  .forgot {
    color: #1765fd;
    font-size: 14px;
  }
  .default-btn {
    color: var(--white-color);
    width: 100%;
    padding: 23px 20px;
  }
  .account {
    font-size: 14px;
    margin-bottom: 0;
    color: #292d32;
    a {
      color: #1765fd;
    }
  }
  .form-check {
    a {
      color: #1765fd;
    }
  }
}
.account-social-link {
  padding-left: 0;
  margin-bottom: 0;
  list-style-image: none;
  text-align: center;
  li {
    display: inline-block;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    a {
      width: 46px;
      height: 46px;
      line-height: 50px;
      text-align: center;
      display: inline-block;
      background-color: #ebf1ff;
      color: #1765fd;
      border-radius: 50%;
      font-size: 20px;
      &:hover {
        background-color: var(--main-color);
        color: var(--white-color);
      }
    }
  }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  .account-area {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .account-content {
    padding: 30px;
  }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .account-area {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .account-content {
    padding: 30px;
  }
}

/* Min width 1320px to Max width 1550px */
@media only screen and (min-width: 1320px) and (max-width: 1550px) {
  .account-area {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
</style>
