<template>
    <div class="all-section-area">
        <MainHeader />
        <main class="main-content-wrap">
            <PageTitle mainTitle="Profil" pageTitle="Profile" />
            <ProfileContent />
            <div class="flex-grow-1"></div>
            <FooterArea />
        </main>
    </div>
</template>

<script>
import MainHeader from '../Layouts/MainHeader'
import PageTitle from '../Common/PageTitle'
import ProfileContent from '../Profile/ProfileContent'
import FooterArea from '../Layouts/FooterArea'

export default {
    name: 'ProfilePage',
    components: {
        MainHeader,
        PageTitle,
        ProfileContent,
        FooterArea,
    },
    mounted () {
        document.body.classList.add('body-bg-f8faff')
    },
}
</script>