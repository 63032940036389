<template>
  <div class="account-area">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="account-content">
            <div class="account-header">
              <router-link to="/">
                <!--                                 <img src="../../assets/images/main-logo.svg" alt="main-logo">
 -->
                <h1>
                  <strong style="color: #0263ac">Trans</strong
                  ><strong style="color: #00993c">Vie</strong><span></span>
                </h1>
              </router-link>
              <h3>Mot de passe oublié</h3>
            </div>

            <form @submit.prevent class="account-wrap">
              <div class="form-group mb-24 icon">
                <input type="email" class="form-control" placeholder="Email" />
                <img src="../../assets/images/icon/sms.svg" alt="sms" />
              </div>
              <div class="form-group mb-24">
                <router-link to="/login" class="forgot">Connexion</router-link>
              </div>
              <div class="form-group mb-24">
                <button type="submit" class="default-btn">Envoyer</button>
              </div>
              <!--  <div class="form-group mb-24">
                                <p class="account text-center">Forgot It? <router-link to="/register">Send me Back</router-link></p>
                            </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgetPasswordPage",
}
</script>

<style lang="scss">
.account-area {
  background-image: url(../../assets/images/account-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 100vh;
}
.account-content {
  max-width: 510px;
  margin: auto;
  background-color: var(--white-color);
  border-radius: 30px;
  padding: 50px;
}
.account-header {
  text-align: center;
  img {
    margin-bottom: 30px;
  }
  h3 {
    margin-bottom: 30px;
    padding-top: 30px;
    border-top: 1px solid #ebf1ff;
    font-size: 20px;
  }
}
.account-wrap {
  .forgot {
    color: #1765fd;
    font-size: 14px;
  }
  .default-btn {
    color: var(--white-color);
    width: 100%;
    padding: 23px 20px;
  }
  .account {
    font-size: 14px;
    margin-bottom: 0;
    color: #292d32;
    a {
      color: #1765fd;
    }
  }
  .form-check {
    a {
      color: #1765fd;
    }
  }
}
.account-social-link {
  padding-left: 0;
  margin-bottom: 0;
  list-style-image: none;
  text-align: center;
  li {
    display: inline-block;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    a {
      width: 46px;
      height: 46px;
      line-height: 50px;
      text-align: center;
      display: inline-block;
      background-color: #ebf1ff;
      color: #1765fd;
      border-radius: 50%;
      font-size: 20px;
      &:hover {
        background-color: var(--main-color);
        color: var(--white-color);
      }
    }
  }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  .account-area {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .account-content {
    padding: 30px;
  }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .account-area {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .account-content {
    padding: 30px;
  }
}

/* Min width 1320px to Max width 1550px */
@media only screen and (min-width: 1320px) and (max-width: 1550px) {
  .account-area {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
</style>
