<template>
  <div class="account-area">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="account-content">
            <div class="account-header">
              <router-link to="/">
                <h1>
                  <strong style="color: #0263ac">Trans</strong
                  ><strong style="color: #00993c">Vie</strong><span></span>
                </h1>
              </router-link>
              <h3>Changer le mot de passe</h3>
            </div>
            <div v-if="showError" class="alert alert-danger" role="alert">
            {{ message}}
            </div>
            <form @submit.prevent class="account-wrap">
              <div class="form-group mb-24 icon">
                <input
                  type="password"
                  v-model="password"
                  class="form-control"
                  placeholder="Mot de passe"
                />
                <img src="../../assets/images/icon/key.svg" alt="key" />
              </div>
              <div class="form-group mb-24 icon">
                <input
                  type="password"
                  v-model="password_conf"
                  class="form-control"
                  placeholder="Confirmer le mot de passe"
                />
                <img src="../../assets/images/icon/key.svg" alt="key" />
              </div>

              <div class="form-group mb-24">
                <router-link to="/login" class="forgot">Connexion</router-link>
              </div>

              <div class="form-group mb-24">
                <b-button
                  :disabled="!isFormValid"
                  @click="ChangePassword"
                  variant="primary"
                  class="default-btn"
                  >Envoyer</b-button
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../axios"
export default {
  name: "ChangePasswordPage",
  data: () => {
    return {
      showError:false,
      message:"",
      password_conf: "",
      password: ""
    };
  },
  computed: {
    isFormValid() {
      return (
        this.password_conf !== "" &&
        this.password !== "" &&
        this.password === this.password_conf
      );
    },
  },

  methods: {
    ChangePassword() {
      const Url = "/change-password/"
      const config = {
        method: "PUT",
        url: Url,
        data: {
          password: this.password
        },
      };
      axios(config)
        .then((response) => {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data.accessToken}`
          axios.defaults = JSON.parse(JSON.stringify(axios.defaults))
          this.$router.push("/login")
        })
        .catch((error) => {
          //this.showError(error.response.data.non_field_errors[0])
          this.showError = true
          this.message = error.response.data.message[0]
          this.closeTime()
        });
    },

    clear() {
      this.password_conf = ""
      this.password = ""
    },
    closeTime(){
      setTimeout(
        function () {
          this.showError = false
        }.bind(this), 5000)

    }
  },

  mounted() {
    document.body.classList.add("body-bg-f8faff")
  },
};
</script>

<style lang="scss">
.account-area {
  background-image: url(../../assets/images/account-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 100vh;
}
.account-content {
  max-width: 510px;
  margin: auto;
  background-color: var(--white-color);
  border-radius: 30px;
  padding: 50px;
}
.account-header {
  text-align: center;
  img {
    margin-bottom: 30px;
  }
  h3 {
    margin-bottom: 30px;
    padding-top: 30px;
    border-top: 1px solid #ebf1ff;
    font-size: 20px;
  }
}
.account-wrap {
  .forgot {
    color: #1765fd;
    font-size: 14px;
  }
  .default-btn {
    color: var(--white-color);
    width: 100%;
    padding: 23px 20px;
  }
  .account {
    font-size: 14px;
    margin-bottom: 0;
    color: #292d32;
    a {
      color: #1765fd;
    }
  }
  .form-check {
    a {
      color: #1765fd;
    }
  }
}
.account-social-link {
  padding-left: 0;
  margin-bottom: 0;
  list-style-image: none;
  text-align: center;
  li {
    display: inline-block;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    a {
      width: 46px;
      height: 46px;
      line-height: 50px;
      text-align: center;
      display: inline-block;
      background-color: #ebf1ff;
      color: #1765fd;
      border-radius: 50%;
      font-size: 20px;
      &:hover {
        background-color: var(--main-color);
        color: var(--white-color);
      }
    }
  }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  .account-area {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .account-content {
    padding: 30px;
  }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .account-area {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .account-content {
    padding: 30px;
  }
}

/* Min width 1320px to Max width 1550px */
@media only screen and (min-width: 1320px) and (max-width: 1550px) {
  .account-area {
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
</style>
