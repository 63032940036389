<template>
  <div class="my-devices-area mt-20">
    <div class="container-fluid">
      <div class="container">
        <div class="row">
        <div  v-if="showError" class="alert alert-danger col-md-3 offset-md-3" role="alert">
            <p v-for="item in message" :key="item">{{ item}}</p>
       </div>
      </div>
      <div class="row">
      <div v-if="showSuccess" class="alert alert-success col-md-3 offset-md-3" role="alert">
          <p>{{ message1}}</p>
      </div>
      </div>
      </div>
      <div class="sidebar-left">
        <div class="sidebar">
          <div class="drive-wrap">
            <h4>Nos projets</h4>
            <ul class="drive-list-wrap">
              <li>
                <router-link to="/">
                  <img
                    src="../../assets/images/icon/monitor.svg"
                    alt="monitor"
                  />
                  Applications
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="content-right">
        <div class="my-file-area">
          <div
            class="form-file-upload d-flex justify-content-between align-items-center"
          >
            <form class="search-form">
              <b-form-input
                type="text"
                class="form-control"
                placeholder="Recherche"
                v-model="filter"
              ></b-form-input>
              <img
                src="../../assets/images/icon/search-normal.svg"
                alt="search-normal"
              />
            </form>

            <ul v-if="userRole === 'admin'" class="create-upload d-flex">
              <li>
                <b-button
                  @click="resetForm"
                  type="button"
                  pill
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop2"
                  >Ajouter</b-button
                >
              </li>
              <li>
                <!-- <b-button type="button" pill variant="primary"
                  >Membres</b-button
                > -->
              </li>
            </ul>
          </div>

          <div class="quick-access">
            <div class="row">
              <div
                v-for="application in applis"
                :key="application"
                class="col-xxl-3 col-sm-6"
              >
                <div v-if="userRole === 'admin'" class="single-folder">
                  <div class="dropdown text-end">
                    <button
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src="../../assets/images/icon/dots.svg" alt="dots" />
                    </button>

                    <div class="dropdown-menu">
                      <a
                        @click="openUrl(application.url)"
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                      >
                        <i class="bx bx-navigation"></i>Visiter
                      </a>
                      <!--  <a class="dropdown-item d-flex align-items-center" href="#">
                                                <i class='bx bx-save'></i>Ajouter un membre
                                            </a> -->
                      <a
                        @click="show(application)"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop6"
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                      >
                        <i class="bx bxs-show"></i>Détails
                      </a>
                      <a
                        @click="edit(application)"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop4"
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                      >
                        <i class="bx bx-hdd"></i> Modifier
                      </a>
                      <a
                        @click="deleted(application)"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop5"
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                      >
                        <i class="bx bx-trash"></i> Supprimer
                      </a>
                    </div>
                  </div>

                  <div class="text-center file">
                    <img
                      src="../../assets/images/file/interface.svg"
                      alt="file"
                    />
                  </div>
                  <h6>{{ application.name }}</h6>
                </div>
                <div
                  @click="openUrl(application.url)"
                  v-if="userRole === 'user'"
                  class="single-folder"
                >
                  <div class="text-center file">
                    <img
                      src="../../assets/images/file/interface.svg"
                      alt="file"
                    />
                  </div>
                  <h6>{{ application.name }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="staticBackdrop2"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Ajouter une application
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="my-0">Nom</p>
          <b-form-input class="mb-2" v-model="name"></b-form-input>

          <p class="my-0">Url</p>
          <b-form-input type="url" class="mb-2" v-model="url"></b-form-input>

          <p class="my-0">Compagnie</p>
          <b-form-select
            multiple
            group
            v-model="companies"
            :options="companies_liste"
            autocomplete="on"
            class="mb-3"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </div>
        <div class="modal-footer">
          <b-button variant="danger" data-bs-dismiss="modal" @click="resetForm"
            >Quitter</b-button
          >
          <b-button
            :disabled="!isFormValid"
            variant="success"
            data-bs-dismiss="modal"
            @click="save"
            >Valider</b-button
          >
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="staticBackdrop4"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Modifier l'application
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="my-0">Nom</p>
          <b-form-input class="mb-2" v-model="name"></b-form-input>

          <p class="my-0">Url</p>
          <b-form-input type="url" class="mb-2" v-model="url"></b-form-input>

          <p class="my-0">Compagnie</p>
          <b-form-select
            multiple
            group
            v-model="companies"
            :options="companies_liste"
            autocomplete="on"
            class="mb-3"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </div>
        <div class="modal-footer">
          <b-button variant="danger" data-bs-dismiss="modal" @click="resetForm"
            >Quitter</b-button
          >
          <b-button
            :disabled="!isFormValid"
            variant="success"
            data-bs-dismiss="modal"
            @click="updateValidate"
            >Modifier</b-button
          >
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="staticBackdrop5"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Supprimer l'application
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Voulez-vous supprimer l'application? <b>{{ applisName }}</b>
          </p>
        </div>
        <div class="modal-footer">
          <b-button variant="danger" data-bs-dismiss="modal" @click="resetForm"
            >Quitter</b-button
          >

          <b-button
            variant="success"
            data-bs-dismiss="modal"
            @click="deletedValidate"
            >supprimer</b-button
          >
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="staticBackdrop6"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Détails de l'application
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="my-0">Nom</p>
          <b-form-input disabled class="mb-2" v-model="name"></b-form-input>

          <p class="my-0">Url</p>
          <b-form-input
            disabled
            type="url"
            class="mb-2"
            v-model="url"
          ></b-form-input>

          <p class="my-0">Compagnie</p>
          <b-form-select
            disabled
            multiple
            group
            v-model="companies"
            :options="companies_liste"
            autocomplete="on"
            class="mb-3"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../axios";
export default {
  name: "MyDevices",
  data() {
    return {
      showSuccess:false,
      showError:false,
      message:[],
      message1:"",
      userRole: "",
      filter: "",
      applisName: "",
      name: "",
      url: "",
      applis: [],
      companies: [],
      companies_liste: [],
    };
  },
  computed: {
    isFormValid() {
      return this.name !== "" && this.url !== "" && this.companies.length !== 0;
    },
  },
  watch: {
    filter(value) {
      if (value) {
        let namefc = value.toUpperCase();
        let rep = [];
        for (var i = 0; i < this.usersRech.length; i++) {
          if (this.usersRech[i].rechInfo.toUpperCase().includes(namefc)) {
            rep.push(this.usersRech[i]);
          }
        }
        if (rep.length) {
          this.applis.length = 0;
          this.applis = rep.sort((a, b) =>
            a.rechInfo.localeCompare(b.rechInfo)
          );
        }

        if (rep.length === 0) {
          this.applis = [];
        }
      } else {
        this.applis = [];
        window.ApplisTransvie.getApplis();
      }
    },
  },
  methods: {
    closeTime(){
      setTimeout(
        function () {
          this.showSuccess = false
          this.showError = false
        }.bind(this), 10000)
    },
    openUrl(link) {
      window.open(link, "_blank");
    },
    resetForm() {
      this.companies = [];
      this.name = "";
      this.url = "";
    },
    deletedValidate() {
      axios
        .delete(`applis/${this.applisId}/`)
        .then(function () {
          window.ApplisTransvie.message.push('Application supprimée avec succès')
          window.ApplisTransvie.showError = true
          window.ApplisTransvie.getApplis()
          window.ApplisTransvie.closeTime()

        })
        .catch(() => {});
    },
    deleted(row) {
      this.applisId = row.id;
      this.applisName = row.name;
    },
    save() {
      this.input = {
        name: this.name,
        url: this.url,
        companies: this.companies,
      };
      axios
        .post("applis/", this.input)
        .then(function () {
          window.ApplisTransvie.message1='Application ajoutée avec succès'
          window.ApplisTransvie.showSuccess = true
          window.ApplisTransvie.getApplis()
          window.ApplisTransvie.closeTime()

        })
        .catch(function (error) {
          window.ApplisTransvie.showError = true
            window.ApplisTransvie.message=[]
            if (error.response.data.name) {
              window.ApplisTransvie.message.push(error.response.data.name[0])
            } if (error.response.data.companies) {
              window.ApplisTransvie.message.push(error.response.data.companies[0])
            }
            if (error.response.data.url) {
              window.ApplisTransvie.message.push(error.response.data.url[0])
            }
            window.ApplisTransvie.closeTime()

        })
    },
    updateValidate() {
      this.input = {
        name: this.name,
        url: this.url,
        companies: this.companies,
      };
      axios
        .patch(`applis/${this.applisId}/`, this.input)
        .then(function () {
          window.ApplisTransvie.message1='Application modifiée avec succès'
          window.ApplisTransvie.showSuccess = true
          window.ApplisTransvie.getApplis()
          window.ApplisTransvie.closeTime()

        })
        .catch(function (error) {
          window.ApplisTransvie.showError = true
            window.ApplisTransvie.message=[]
            if (error.response.data.name) {
              window.ApplisTransvie.message.push(error.response.data.name[0])
            } if (error.response.data.companies) {
              window.ApplisTransvie.message.push(error.response.data.companies[0])
            }
            if (error.response.data.url) {
              window.ApplisTransvie.message.push(error.response.data.url[0])
            }
            window.ApplisTransvie.closeTime()

        })
    },
    edit(tr) {
      this.resetForm();
      this.applisId = tr.id;
      this.name = tr.name;
      this.url = tr.url;
      for (var i = 0; i < tr.companies.length; i++) {
        this.companies.push(tr.companies[i].id);
      }
    },
    show(tr) {
      this.resetForm();
      this.name = tr.name;
      this.url = tr.url;
      for (var i = 0; i < tr.companies.length; i++) {
        this.companies.push(tr.companies[i].id);
      }
    },

    getApplis() {
      axios
        .get("applis/")
        .then((response) => {
          this.applis = [];
          this.usersRech = [];
          const results = response.data;
          results.forEach((event) => {
            this.applis.push({
              id: event.id,
              name: event.name,
              url: event.url,
              companies: event.companies,
              rechInfo: event.name,
            });
            this.usersRech.push({
              id: event.id,
              name: event.name,
              url: event.url,
              companies: event.companies,
              rechInfo: event.name,
            });
          });
        })
        .catch(() => {});
    },
    getCompanies() {
      axios
        .get("companies/")
        .then((response) => {
          this.companies_liste = response.data;
        })
        .catch(() => {});
    },
  },

  created() {
    window.ApplisTransvie = this;
    this.userRole = JSON.parse(localStorage.getItem("userData")).role;
    this.getApplis();
    if (this.userRole === "admin") {
      this.getCompanies();
    }
  },
};
</script>

<style lang="scss">
.my-devices-area {
  .sidebar-left {
    float: left;
    .sidebar {
      background-color: var(--white-color);
      box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
      padding: 30px;
      width: 400px;
      border-radius: 30px;
      margin-bottom: 24px;
      .sidebar-content {
        height: calc(100vh - 321px);
      }
      .drive-wrap {
        h4 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 30px;
        }
      }
      .drive-list-wrap {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        border-bottom: 1px solid #ebf1ff;
        margin-bottom: 30px;
        li {
          margin-bottom: 30px;
          a {
            font-weight: 500;
            font-size: 16px;
            color: #67748e;
            img {
              margin-right: 5px;
              position: relative;
              top: -2px;
            }
            &.active {
              background-color: #5c31d6;
              color: var(--white-color);
              display: block;
              width: 100%;
              padding: 10px 20px;
              border-radius: 10px;
              img {
                filter: brightness(100);
              }
            }
          }
        }
      }
      .storage-status {
        position: relative;
        padding-left: 40px;
        img {
          position: absolute;
          top: 17px;
          left: 0;
        }
        .progress {
          background-color: transparent;
          position: relative;
          z-index: 1;
          border-radius: 30px;
          --bs-progress-height: 10px;
          &::before {
            content: "";
            position: absolute;
            top: 3px;
            left: 0;
            width: 100%;
            height: 5px;
            background-color: #e2eafb;
            z-index: -1;
            border-radius: 30px;
          }
          .progress-bar {
            border-radius: 30px;
          }
        }
        p {
          margin-bottom: 5px;
          font-size: 14px;
          color: #292d32;
          span {
            color: #5c31d6;
          }
        }
        a {
          color: #1765fd;
          font-size: 14px;
          margin-top: 5px;
          display: inline-block;
        }
      }
    }
  }
  .content-right {
    float: right;
    width: calc(100% - 424px);
    margin-bottom: 24px;
    .my-file-area {
      background-color: var(--white-color);
      box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
      padding: 30px;
      padding-bottom: 0;
      border-radius: 30px;
      .form-file-upload {
        border-bottom: 1px solid #ebf1ff;
        padding-bottom: 30px;
        margin-bottom: 30px;
        .search-form {
          position: relative;
          .form-control {
            border-radius: 50px;
            padding-left: 55px;
            height: 50px;
          }
          img {
            position: absolute;
            top: 15px;
            left: 20px;
          }
        }
        .create-upload {
          list-style: none;
          padding: 0;
          margin-bottom: 0;
          li {
            margin-right: 30px;
            &:last-child {
              margin-right: 0;
            }
            .upload-btn {
              background-color: var(--main-color);
              color: var(--white-color);
              padding: 10px 20px;
              border-radius: 10px;
              img {
                margin-left: 5px;
              }
              &.upload {
                background-color: #5c31d6;
              }
            }
          }
        }
      }
      .quick-access {
        h3 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 30px;
        }
      }
      .single-folder {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 25px #cad6f266;
        border: 1px solid #dfe9ff;
        border-radius: 20px;
        padding: 30px;
        position: relative;
        margin-bottom: 30px;
        .file {
          img {
            height: 97px;
          }
        }
        .dropdown {
          margin-bottom: 20px;
          .dropdown-toggle {
            background-color: transparent;
            position: relative;
            &::after {
              display: none;
            }
          }
          .dropdown-menu {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 5px 25px #cad6f266;
            border: 1px solid #dfe9ff;
            border-radius: 10px;
            margin-top: 35px;
            display: block;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s;
            transform: unset !important;
            left: auto !important;
            right: 0 !important;
            &.show {
              margin-top: 28px !important;
              opacity: 1;
              visibility: visible;
            }
            .dropdown-item {
              color: var(--heading-color);
              padding: 5px 15px 5px 36px;
              position: relative;
              font: {
                size: 12px;
              }
              i {
                color: #686868;
                transition: var(--transition);
                position: absolute;
                left: 15px;
                top: 6px;
                font: {
                  size: 16px;
                }
              }
              &.active,
              &:active {
                color: var(--heading-color);
                background-color: transparent;
                background-color: #f8f9fa;
              }
              &:hover {
                background-color: transparent;
                color: var(--main-color);
                i {
                  color: var(--main-color);
                }
              }
            }
          }
        }
        h6 {
          font-size: 16px;
          margin-bottom: 5px;
          margin-top: 20px;
        }
        span {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  .my-devices-area {
    .sidebar-left {
      float: unset;
      .sidebar {
        width: 100%;
        padding: 20px;
        .drive-list-wrap {
          li {
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
    .content-right {
      width: unset;

      .my-file-area {
        padding: 20px;
        padding-bottom: 0;
        .form-file-upload {
          display: block !important;
          .create-upload li .upload-btn {
            padding: 10px 15px;
            border-radius: 10px;
            display: inline-block;
            margin-top: 10px;
          }
        }
        .form-file-upload {
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
        .single-folde {
          padding: 20px;
        }
      }
    }
  }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .my-devices-area {
    .sidebar-left {
      float: unset;
      .sidebar {
        width: 100%;
        padding: 20px;
        .drive-list-wrap {
          li {
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
    .content-right {
      width: unset;

      .my-file-area {
        padding: 20px;
        padding-bottom: 0;
        .form-file-upload {
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
        .single-folde {
          padding: 20px;
        }
      }
    }
  }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .my-devices-area {
    .sidebar-left {
      .sidebar {
        width: 280px;
      }
    }
    .content-right {
      width: calc(100% - 300px);
    }
  }
}
</style>
